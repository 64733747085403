.user-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 160px;
  text-align: center;

  @media (max-width: 768px) {
    width: 100px;
  }

  .user-image {
    width: 120px;
    height: 120px;
    border-radius: 80px;
    margin-bottom: 10px;
    object-fit: cover;
    -o-object-fit: cover;

    @media (max-width: 768px) {
      width: 60px;
      height: 60px;
    }
  }

  .user-position {
    font-size: 16px;
    color: rgba(0, 0, 0, 1);
    font-weight: 400;
    margin: 0;

    @media (max-width: 768px) {
      font-size: 14px;
    }
  }

  .user-name {
    font-size: 20px;
    color: rgba(0, 0, 0, 1);
    font-weight: 700;
    margin: 0;

    @media (max-width: 768px) {
      font-size: 16px;
    }
  }
}