.editModal {
  padding: 24px;
}

.no-content {
  text-align: center;
  display: block;
  color: #999999;
  font-size: 12px;
  font-weight: 400;
  line-height: 21.6px;
}

.sectionTitle {
  // height: 44px;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 16px;
  border-radius: 4px;
  font-size: 24px;
  font-weight: 400;
  line-height: 24px;
  color: #ffffff;

  &.space {
    justify-content: space-between;
  }

  @media (max-width: 992px) {
    height: 38px;
    font-size: 18px;
    font-weight: 700;
    line-height: 18px;
  }
}

.cardTitle {
  background-color: #0099cc;
  padding: 16px;
  width: 158px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 8px;

  @media (max-width: 992px) {
    flex-direction: row;
    width: 100%;
    padding: 16px 0;
  }

  img {
    width: 48px;
    height: 48px;

    @media (max-width: 992px) {
      width: 36px;
      height: 36px;
      padding: 4.5px 3px 4.5px 3px;
    }
  }

  p {
    font-size: 18px;
    font-weight: 700;
    line-height: 27px;
    text-align: center;
    color: #ffffff;
    padding: 0;
    margin: 0;
  }
}

// CardData
.valueContainer {
  flex: 1;
  padding: 16px 16px;
  // min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 8px;
  background-color: #ffffff;
  overflow: hidden;
  overflow-wrap: break-word;
  word-wrap: break-word;
  -webkit-box-orient: vertical;

  img {
    width: 30px;
    height: 30px;
  }

  p {
    display: flex;
    padding: 0;
    margin: 0;
    font-size: 16px;
    font-weight: 400;
    // line-height: 30px;
    text-align: left;
    color: #333333;
  }
}

.card {
  margin-bottom: 40px;

  @media (max-width: 992px) {
    margin-bottom: 24px;
  }

  .cardContent {
    font-size: 16px;
    font-weight: 400;
    line-height: 28.8px;
    text-align: left;
    color: #333333;
    word-wrap: break-word;
    margin-top: 16px;

    p {
      padding: 0;
      margin: 0;
      line-height: 28.8px;
    }
  }
}

.ChildCard {
  // height:174px;
  padding: 16px;
  margin: 16px 0;
  border: 1px solid #cccccc;

  .title {
    padding-bottom: 8px;
    font-size: 18px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    color: #333333;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .cardContent {
    font-size: 16px;
    font-weight: 400;
    line-height: 28.8px;
    text-align: left;
    color: #333333;
    // max-height: 175px;
    overflow: hidden;
    overflow-wrap: break-word;
    display: -webkit-box;
    word-wrap: break-word;
    -webkit-box-orient: vertical;

    p {
      padding: 0;
      margin: 0;
      line-height: 28.8px;
    }

    .no-content {
      text-align: center;
      display: block;
    }

    // -webkit-line-clamp: 4;
    // line-clamp: 4;

    @media (max-width: 992px) {
      // max-height: 260px;

      // -webkit-line-clamp: 6;
      // line-clamp: 6;
    }
  }

  .showMore {
    padding-top: 8px;
    color: #999999;
    font-size: 12px;
    font-weight: 400;
    line-height: 21.6px;
    text-align: left;

    display: inline-flex;
    align-items: center;
    justify-content: start;
    gap: 8px;
    cursor: pointer;

    img {
      width: 16px;
      height: 16px;
      // rotate: 180deg;
    }
  }
}