.search-results {
  max-width: 1100px;
  margin: 0 auto;
  padding: 20px;
  min-height: 70vh;

  .loadUser {
    display: flex;
    align-items: center;
    justify-content: start;

    .loader {
      margin-left: 50px;
    }

    .loader::before {
      border: 3px solid #333333 !important;
    }
  }

  h2 {
    font-size: 24px;
    font-weight: 900;
    color: rgba(51, 51, 51, 1);
    margin-bottom: 20px;
  }

  .search-results-container {
    position: relative;

    .search-box {
      background-color: #f6f7f7;
      border: 1px solid #e6e6e6;
      border-radius: 4px;
      height: 55px;
      margin-bottom: 30px;
      padding: 10px 5px 10px 20px;
      width: 100%;
      color: rgba(51, 51, 51, 1);
      font-size: 16px;

      @media (max-width: 600px) {
        height: 45px;
        width: 100%;
      }
    }

    .search-icon {
      position: absolute;
      right: 1%;
      width: 25px;
      top: 33%;
      transform: translateY(-50%);

      @media (max-width: 768px) {
        right: 2%;
        top: 31%;
      }
    }
  }

  .user-list {
    display: flex;
    margin-top: 40px;
    flex-wrap: wrap;
    gap: 60px;
    row-gap: 40px;
    justify-content: center;

    @media (max-width: 768px) {
      justify-content: space-between;
      margin-top: 30px;
      gap: 11px;
      row-gap: 24px;
    }

    a {
      text-decoration: none;
    }
  }
}
