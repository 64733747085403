.account-creation-completed-container {
  max-width: 380px;
  height: 100%;
  min-height: 65vh;
  margin: 0 auto;
  padding: 20px;

  h2 {
    color: rgba(51, 51, 51, 1);
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 20px;
    text-align: center;
  }

  p {
    color: rgba(51, 51, 51, 1);
    font-size: 16px;
    text-align: center;
    margin-bottom: 20px;
    line-height: 28px;
  }

  .business-sheet-button {
    margin-top: 30%;
    width: 100%;
    padding: 12px;
    font-size: 16px;
    border: none;
    border-radius: 4px;
    font-weight: 600;
    cursor: pointer;
    background-color: rgba(51, 51, 51, 1);
    color: rgba(255, 255, 255, 1);
    text-align: center;

    &:disabled {
      background-color: rgba(153, 153, 153, 1);
      color: rgba(255, 255, 255, 1);
    }
  }

  .skip-link {
    text-align: center;
    margin-top: 10px;

    a {
      color: rgba(20, 106, 245, 1);
      font-size: 12px;
      text-decoration: underline;
    }
  }
}

@media (max-width: 768px) {
  .account-creation-completed-container {
    padding: 15px;
    height: 100%;

    h2 {
      text-align: left;
      font-size: 20px;
    }

    p {
      text-align: left;
      line-height: 26px;
    }

    .business-sheet-button {
      margin-top: 90%;
    }
  }
}
