a {
  text-decoration: none;
}

.member-list {
  max-width: 1100px;
  margin: 0 auto;
  padding: 20px 12px;
  min-height: 70vh;

  h2 {
    font-size: 24px;
    font-weight: 900;
    color: rgba(51, 51, 51, 1);
    margin-bottom: 20px;
  }

  .user-list {
    display: flex;
    margin-top: 40px;
    flex-wrap: wrap;
    gap: 60px;
    row-gap: 40px;
    justify-content: center;

    @media (max-width: 768px) {
      justify-content: space-between;
      margin-top: 30px;
      gap: 11px;
      row-gap: 24px;
    }
  }
}
