.PreviewHeader {
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
  background-color: rgba(255, 255, 255, 1);
  border-bottom: rgba(230, 230, 230, 1) 1px solid;

  &-left {
    display: flex;
    align-items: center;
    justify-content: center;

    .logo {
      width: 88px;
      margin-right: 20px;
    }
  }
}

@media (max-width: 768px) {
  .PreviewHeader {
    padding: 0 15px;
  }
}
