.forgot-password-form-container {
  max-width: 380px;
  height: 100%;
  min-height: 65vh;
  margin: 0 auto;
  padding: 20px;

  form {
    display: flex;
    width: 100%;
    flex-direction: column;
  }

  h2 {
    color: rgba(51, 51, 51, 1);
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 20px;
    text-align: center;
  }

  label {
    display: block;
    color: rgba(153, 153, 153, 1);
    margin-bottom: 5px;
    font-weight: 500;
    font-size: 14px;
  }

  input[type="email"] {
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    color: rgba(51, 51, 51, 1);
    font-size: 15px;

    &::placeholder {
      font-size: 15px;
      color: rgba(117, 117, 120, 1);
    }
  }

  button[type="submit"] {
    margin-top: 20px;
    width: 100%;
    padding: 12px;
    font-size: 16px;
    border: none;
    border-radius: 4px;
    font-weight: 600;
    cursor: pointer;

    &:disabled {
      background-color: rgba(153, 153, 153, 1);
      color: rgba(255, 255, 255, 1);
    }

    &:not(:disabled) {
      background-color: rgba(51, 51, 51, 1);
      color: rgba(255, 255, 255, 1);
    }
  }
}

@media (max-width: 768px) {
  .forgot-password-form-container {
    padding: 15px;
    height: 100%;

    h2 {
      text-align: left;
      font-size: 20px;
    }

    button[type="submit"] {
      margin-top: 70%;
    }

    input[type="email"] {
      &::placeholder {
        font-size: 13px;
        color: rgba(117, 117, 120, 1);
      }
    }
  }
}
