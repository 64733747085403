.header {
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
  background-color: rgba(255, 255, 255, 1);
  border-bottom: rgba(230, 230, 230, 1) 1px solid;
  z-index: 2000;

  &-left {
    display: flex;
    align-items: center;
    gap: 8%;

    .logo {
      width: 88px;
      margin-right: 20px;
    }

    .search-container {
      position: relative;

      .search-input {
        height: 35px;
        width: 250px;
        padding: 0 40px 0 20px;
        border: 1px solid rgba(230, 230, 230, 1);
        border-radius: 20px;
        font-size: 14px;
        outline: none;
      }

      .search-icon {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  &-right {
    display: flex;
    align-items: center;

    a {
      text-decoration: none;
      display: flex;
      align-items: center;
    }

    .account-icon {
      margin-right: 8px;
      width: 24px;
      height: 24px;
      border-radius: 50%;
    }

    .account-text {
      font-size: 16px;
      color: rgba(51, 51, 51, 1);
    }
  }
}

.header-right {
  img {
    object-fit: cover;
  }
}

@media (max-width: 768px) {
  .header {
    padding: 0 15px;

    &-left .search-container,
    &-right {
      display: none;
    }
  }
}