.toastStyle {
  background-color: #333333 !important;
  color: #fff !important;
  font-size: 14px !important;
}

.BusinessSheet {
  .headerBg {
    position: relative;

    img {
      object-fit: cover;
      max-height: 280px;
      width: 100%;
      padding: 0;
      margin: 0;

      @media (max-width: 600px) {
        max-height: 120px;
      }
    }
  }

  .editProfile {
    // padding: 0 150px;
    position: absolute;
    bottom: 16px;
    right: 150px;
    background-color: white;
    padding: 4px 16px;
    border-radius: 2px;
    gap: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    img {
      width: 16px;
      height: 16px;
    }

    span {
      font-size: 16px;
      font-weight: 400;
      line-height: 16px;
      text-align: left;
      color: #333333;
    }

    @media (max-width: 992px) {
      right: 16px;
    }
  }

  .profile {
    background: #f7f7f7;
    height: 104px;
    display: flex;
    gap: 16px;
    align-items: center;
    padding: 0 150px;

    @media (max-width: 992px) {
      padding: 0px 16px;
    }

    img[alt="profile"] {
      border-radius: 50%;
      border: 2px solid #f7f7f7;
      width: 160px;
      min-width: 160px;
      height: 160px;
      min-height: 160px;
      margin-top: -102px;
      z-index: 100;
      -o-object-fit: cover;
      object-fit: cover;

      @media (max-width: 992px) {
        width: 80px;
        min-width: 80px;
        height: 80px;
        min-height: 80px;
      }
    }

    .profileContent {
      display: flex;
      align-items: center;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      @media (max-width: 992px) {
        flex-direction: column;
        align-items: start;
      }

      .userData {
        display: flex;
        align-items: start;
        justify-content: center;
        gap: 8px;
        flex-direction: column;

        .userCategory {
          margin: 0;
          padding: 0;
          font-size: 16px;
          font-weight: 400;
          line-height: 16px;
          text-align: left;
          color: #333333;
        }

        .userName {
          margin: 0;
          padding: 0;
          font-size: 24px;
          font-weight: 700;
          line-height: 24px;
          text-align: left;
          color: #333333;
        }
      }

      .copyContent {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        flex-direction: column;

        @media (max-width: 992px) {
          flex-direction: row;
          margin-left: -80px;
        }

        .copyImg {
          width: 36px;
          height: 36px;
          background-color: #999999;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;

          img {
            width: 24px;
            height: 24px;
            margin: 0;
            padding: 0;
            border: none;
          }
        }

        span {
          font-size: 12px;
          font-weight: 400;
          line-height: 12px;
          text-align: left;
          color: #333333;
        }
      }
    }
  }

  .businessSheetData-s1 {
    padding: 32px 150px;
    display: flex;
    align-items: start;
    justify-content: center;
    gap: 40px;

    @media (max-width: 992px) {
      flex-direction: column;
      gap: 24px;
      padding: 24px 16px;
    }

    .left {
      flex: 1;
      width: 50%;

      @media (max-width: 992px) {
        width: 100%;
      }
    }

    .right {
      flex: 1;
      width: 50%;

      @media (max-width: 992px) {
        width: 100%;
      }
    }
  }

  .businessSheetData-s2 {
    position: relative;

    img[alt="background"] {
      position: absolute;
      // max-height: fit-content;
      width: 100%;
      height: 100%;
      z-index: -1;
    }

    .section2Container {
      padding: 40px 150px;
      // margin: 0 150px;
      height: fit-content;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 16px;

      @media (max-width: 992px) {
        padding: 16px;
        gap: 24px;
      }

      .goldenEgg {
        // min-height: 106px;
        // display: flex;
        // align-items: center;
        // justify-content: start;
        // gap: 16px;
        width: 100%;
        display: grid;
        grid-template-columns: auto 1fr 1fr 1fr;
        grid-gap: 16px;

        @media (max-width: 992px) {
          grid-template-columns: 1fr;
          grid-gap: 8px;
        }
      }

      .goldenGoose {
        // min-height: 215px;
        width: 100%;
        display: grid;
        grid-template-columns: auto 1fr 1fr 1fr;
        grid-column-gap: 16px;

        .valueContainer {
          flex-direction: column;
          align-items: start;
          display: -webkit-box;
        }

        @media (max-width: 992px) {
          grid-template-columns: 1fr;
          grid-gap: 8px;
        }
      }

      .goldenFarmer {
        // min-height: 106px;
        // display: flex;
        // align-items: center;
        // justify-content: start;
        // gap: 16px;
        width: 100%;
        display: grid;
        grid-template-columns: auto 1fr 1fr 1fr;
        grid-gap: 16px;

        @media (max-width: 992px) {
          grid-template-columns: 1fr;
          grid-gap: 8px;
        }
      }

      .companyStrengths {
        // min-height: 106px;
        width: 100%;
        display: grid;
        grid-template-columns: auto 1fr;
        grid-column-gap: 16px;

        .valueContainer {
          align-items: start;
          display: -webkit-box;
        }

        @media (max-width: 992px) {
          grid-template-columns: 1fr;
          grid-gap: 8px;
        }
      }

      .powerWords {
        // min-height: 142px;
        width: 100%;
        display: grid;
        grid-template-columns: auto 1fr;
        grid-column-gap: 16px;

        .valueContainer {
          display: -webkit-box;
        }

        .gridContainer {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;
          grid-template-rows: 1fr 1fr;
          grid-column-gap: 16px;
          grid-row-gap: 8px;

          @media (max-width: 992px) {
            grid-template-columns: 1fr 1fr;
            grid-gap: 8px;
          }
        }

        @media (max-width: 992px) {
          grid-template-columns: 1fr;
          grid-gap: 8px;
        }
      }

      .products {
        // min-height: 177px;
        width: 100%;
        display: grid;
        grid-template-columns: auto 1fr 1fr 1fr;
        grid-column-gap: 16px;

        .valueContainer {
          align-items: start;
          display: -webkit-box;
        }

        @media (max-width: 992px) {
          grid-template-columns: 1fr;
          grid-gap: 8px;
        }
      }
    }
  }
}