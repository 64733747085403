.red {
  color: rgb(253, 7, 7) !important;
  scale: 1.2;
}

.textArea {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;

  .maxLength {
    margin-left: auto;
    font-family: Noto Sans JP;
    font-size: 10px;
    font-weight: 400;
    line-height: 10px;
    color: #999999;
    margin-bottom: 4px;
  }

  textarea {
    scrollbar-width: thin;
    scrollbar-color: #333333 #ccc;
    padding: 16px;
    border-radius: 4px;
    border: 1px solid #e6e6e6;
    width: 100%;
    padding-right: 0;
    height: calc(100vh - 450px);
    // min-height: 450px;
    min-height: 200px;
    outline: none;
    font-family: Noto Sans JP;
    font-size: 16px;
    font-weight: 400;
    line-height: 28.8px;
    text-align: left;
  }
}

.input {
  width: 100%;
  margin-bottom: 16px;

  .lableContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #999999;

    label {
      font-family: Noto Sans JP;
      font-size: 14px;
      font-weight: 400;
      line-height: 14px;
      text-align: left;
      margin-bottom: 8px;
    }

    .maxLength {
      font-family: Noto Sans JP;
      font-size: 10px;
      font-weight: 400;
      line-height: 15px;
      text-align: right;
    }
  }

  input {
    width: 100%;
    outline: none;
    padding: 16px;
    padding-right: 0;
    border-radius: 4px;
    border: 1px solid #e6e6e6;
    font-family: Noto Sans JP;
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    color: #333333;
  }
}

.font {
  margin-bottom: 40px;

  .title {
    font-family: Noto Sans JP;
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    color: #333333;
    margin-bottom: 24px;
    display: block;
  }

  .fontsGrid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(138px, 1fr));
    gap: 16px;
    margin: 0 auto;

    .item {
      display: flex;
      align-items: center;
      padding: 16px;
      border-radius: 4px;
      border: 1px solid #e6e6e6;
      gap: 8px;

      input[type="radio"] {
        accent-color: #1c1b1f;
      }

      span {
        font-size: 30px;
        font-weight: 700;
        line-height: 45px;
        color: #333333;
      }
    }
  }
}

.theme {
  margin-bottom: 40px;

  .title {
    font-family: Noto Sans JP;
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    color: #333333;
    margin-bottom: 24px;
    display: block;
  }

  .themesGrid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(107.2px, 1fr));
    gap: 16px;
    margin: 0 auto;

    .item {
      display: flex;
      align-items: center;
      padding: 16px;
      border-radius: 4px;
      border: 1px solid #e6e6e6;
      gap: 8px;

      input[type="radio"] {
        accent-color: #1c1b1f;
      }

      span {
        width: 43.2px;
        height: 30px;
      }
    }
  }
}

.uploadImage {
  margin-bottom: 40px;

  .title {
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    text-align: center;
    color: #333333;
    margin-bottom: 24px;
    display: block;

    @media (max-width: 772px) {
      text-align: left;
    }
  }

  .uploadWrapper {
    display: flex;
    align-items: end;
    justify-content: center;
    gap: 8px;

    @media (max-width: 772px) {
      justify-content: start;
    }

    .imageValue {
      object-fit: cover;
    }

    .headerBackgroundImage {
      height: 152px;
      width: 95%;
    }

    .profileImage {
      height: 120px;
      width: 120px;
      border-radius: 50%;

      @media (max-width: 772px) {
        height: 64px;
        width: 64px;
      }
    }

    .referralSheetBackgroundImage {
      height: 400px;
      width: 95%;

      @media (max-width: 772px) {
        height: 240px;
      }
    }

    .controls {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 4px;

      .update {
        cursor: pointer;
        width: 24px;
        height: 24px;
      }

      .delete {
        cursor: pointer;
        width: 24px;
        height: 24px;
      }
    }
  }

  input[type="file"] {
    display: none;
  }
}
