/* The Modal (background) */
.modal {
  position: fixed;
  z-index: 1000;
  left: 0;
  top: -100%;
  width: 100%;
  height: 100%;
  overflow: auto;
  overflow-x: hidden;
  transition: top 0s ease-in-out 0.3s;

  &.active {
    top: 0;
    transition: top 0s ease-in-out 0s;

    .overlay {
      top: 0;
      opacity: 1;
      transition: opacity 0.3s ease-in-out 0s;
    }

    .modalBox {
      opacity: 1;
      scale: 1;
    }
  }

  .overlay {
    position: fixed;
    z-index: 10;
    left: 0;
    top: -100%;
    width: 100%;
    height: 100%;
    opacity: 0;
    background-color: rgba(0, 0, 0, 0.4);
    transition: opacity 0.3s ease-in-out 0.3s;
  }

  .modalBox {
    border-radius: 8px;
    background-color: #fefefe;
    margin: 50px auto;
    // padding: 20px;
    scale: 0;
    z-index: 20;
    border: 1px solid #888;
    min-height: 100px;
    position: relative;
    overflow-x: hidden;
    transition: all 0.3s ease-in-out;

    &.sm {
      width: 572px;
    }

    &.md {
      width: 60%;
    }

    &.lg {
      width: 90%;
    }

    @media (max-width: 992px) {
      &.lg,
      &.md,
      &.sm {
        width: 90%;
      }
    }

    .closeBtn {
      background-color: #fefefe;
      border-radius: 8px;
      color: #aaa;
      font-size: 35px;
      font-weight: bold;
      outline: none;
      border: none;
      position: absolute;
      top: 0px;
      right: 0px;
      padding: 0 10px;
      width: fit-content;
      height: fit-content;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0;
      min-width: fit-content !important;
      width: fit-content;
      transition: all 0.5s;

      &:hover {
        color: black;
        text-decoration: none;
        cursor: pointer;
        rotate: 720deg;
      }
    }

    .modalBoxChildern {
      color: #000;
    }
  }
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeInZoomIn {
  from {
    opacity: 0;
    transform: scale(0.95);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes fadeInZoomOut {
  from {
    opacity: 1;
    transform: scale(1);
  }

  to {
    opacity: 0;
    transform: scale(0.95);
  }
}
