.stepper-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 30px;
  // margin-bottom: 50px;

  .steps-number {
    color: #333333;
    font-family: Noto Sans JP;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    padding-bottom: 4px;
  }

  .progress-bar {
    position: relative;
    width: 100%;
    height: 6px;
    border-radius: 3px;
    overflow: hidden;
    margin-bottom: 20px;

    &::-webkit-progress-bar {
      background-color: #d9d9d9;
    }

    &::-webkit-progress-value {
      background-color: #38c697;
    }
  }

  .steps {
    margin-bottom: 20px;
    display: flex;
    align-items: start;
    justify-content: center;
    width: 100%;
    max-width: 600px;

    .step-container {
      text-align: center;
      font-size: 14px;
      transition: all 0.3s ease;
      width: 100%;

      h2 {
        text-align: center;
        font-family: Noto Sans JP;
        font-size: 24px;
        font-weight: 700;
        line-height: 34.75px;
      }

      .step-content {
        width: 100%;
      }
    }
  }

  .button-group {
    display: flex;
    justify-content: center;
    gap: 16px;
    width: 100%;
    max-width: 600px;
    flex-wrap: wrap;

    button {
      flex: 1;
      min-width: 171px;
    }
  }

  .skipBtn {
    background-color: white;
    outline: none;
    border: none;
    cursor: pointer;
    font-family: Noto Sans JP;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    color: #146af5;
    text-decoration: underline;
    margin-top: 16px;
  }

  .orBtn {
    font-family: Noto Sans JP;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    color: #999999;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 16px;
    max-width: 600px;
    margin-top: 16px;

    &::after,
    &::before {
      content: "";
      flex: 1;
      height: 1px;
      background: #d9d9d9;
    }
  }

  .alreadyHaveSheet {
    margin-top: 16px;
    font-family: Noto Sans JP;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
  }
}
