.mail-confirmation-container {
  max-width: 380px;
  min-height: 65vh;
  height: 100%;
  margin: 0 auto;
  padding: 20px;

  h2 {
    color: rgba(51, 51, 51, 1);
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 20px;
    text-align: center;
  }

  p {
    color: rgba(51, 51, 51, 1);
    font-size: 14px;
    margin-bottom: 20px;
  }

  .email-info-container {
    background-color: rgba(250, 250, 250, 1);
    padding: 15px;
    margin-bottom: 20px;
    text-align: center;

    h3 {
      color: rgba(51, 51, 51, 1);
      font-size: 12px;
      margin-bottom: 5px;
    }

    .email {
      font-size: 16px;
      color: rgba(51, 51, 51, 1);
      font-weight: 500;
    }
  }

  button[type="button"] {
    width: 100%;
    padding: 12px;
    font-size: 16px;
    border: none;
    border-radius: 4px;
    font-weight: 600;
    cursor: pointer;
    background-color: rgba(51, 51, 51, 1);
    color: rgba(255, 255, 255, 1);
  }

  @media (max-width: 768px) {
    padding: 15px;
    height: 100%;

    h2 {
      font-size: 20px;
      text-align: left;
    }

    button[type="button"] {
      margin-bottom: 10px;
      margin-top: 30%;
    }
  }
}
