.blog {
  background: #f7f7f7;
  padding: 40px 150px;
  display: flex;
  justify-content: center;

  .blogContainer {
    width: 100%;
    max-width: 1100px;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
  }

  @media (max-width: 992px) {
    padding: 12px 16px;
  }




  .blogTitle {
    margin-bottom: 16px;

    display: flex;
    align-items: center;
    justify-content: center;

    .back {
      color: #999999;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        padding: 0 8px;
      }

    }

    .shortTitle {
      font-family: Noto Sans JP;
      font-size: 14px;
      font-weight: 400;
      line-height: 16.1px;
      text-align: left;
      color: #999999;
    }

  }

  .blogImg {
    width: 100%;
    max-width: 1100px;
    aspect-ratio: 2/1;
    // background: #333333;
    margin-bottom: 32px;
  }

  .data {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .container {
      max-width: 700px;

      .date {
        font-family: Noto Sans JP;
        font-size: 14px;
        font-weight: 400;
        line-height: 14px;
        text-align: left;
        color: #999999;
        margin-bottom: 8px;
      }

      .title {
        font-family: Noto Sans JP;
        font-size: 24px;
        font-weight: 900;
        line-height: 36px;
        text-align: left;
        color: #333333;
        margin-bottom: 24px;
      }

      .description {
        font-family: Noto Sans JP;
        font-size: 16px;
        font-weight: 400;
        line-height: 32px;
        text-align: left;
        margin-bottom: 80px;
      }
    }
  }
}